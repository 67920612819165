import type { DashboardProjectResource } from "~/client/resources/dashboardProjectResource";
import type { ProjectOrSummaryResource, GitRefResource } from "~/client/resources/projectResource";
import { getURISafeGitRef } from "~/client/resources/projectResource";
import { deploymentLinks } from "../DeploymentsRoute/deploymentRouteLinks";
import { opsRouteLinks } from "../OperationsRoute/opsRouteLinks";
import { projectInsightsLinks } from "../ProjectInsights/ProjectInsightsRoutes/projectInsightsLinks";
import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import variablesLinks from "../Variables/variablesRouteLinks";
import { channel, trigger, scheduledTrigger, runbook } from "../routeLinkHelpers";
import type { BranchSpecifier } from "./BranchSpecifier";
import { getURISafeBranchSpecifier, ShouldUseDefaultBranch } from "./BranchSpecifier";

export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`,
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`,
    },
    trigger: trigger(root),
    channel: channel(root),
    release: (releaseId: string) => `${root}/releases/${releaseId}`,
    runbookSnapshot: (runbookSnapshotId: string) => `${root}/snapshots/${runbookSnapshotId}`, // Needed for audit-screen document redirection.
    runbook: runbook(root), // Needed for audit-screen document redirection.
    project: (projectSlug: string | ProjectOrSummaryResource | DashboardProjectResource) => projectRoutes(root, projectSlug),
    projectRoutes: () => projectRoutes(root, ":projectSlug"),
    projectBranch: (projectSlug: string, gitRef: BranchSpecifier | GitRefResource | undefined) => branchRoutes(root, projectSlug, gitRef),
    projectBranchRoutes: () => branchRoutes(root, ":projectSlug", ":branchName"),
    importExport: {
        root: `${root}/projects/importExport/overview`,
    },
    importTask: (taskId: string) => {
        const specificRoot = `${root}/projects/importExport/import/${taskId}`;
        return {
            root: specificRoot,
        };
    },
    exportTask: (taskId: string) => {
        const specificRoot = `${root}/projects/importExport/export/${taskId}`;
        return {
            root: specificRoot,
        };
    },
    import: {
        root: `${root}/projects/importExport/import`,
    },
    export: {
        root: `${root}/projects/importExport/export`,
    },
});

const isGitRefResource = (branch: unknown): branch is GitRefResource => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (branch as GitRefResource).CanonicalName !== undefined;
};

const rootForBranchResource = (projectRootLink: string, branch: GitRefResource) => {
    return `${projectRootLink}/branches/${getURISafeGitRef(branch)}`;
};

const rootForBranchSpecifier = (projectRootLink: string, branch: BranchSpecifier) => {
    return ShouldUseDefaultBranch(branch) ? projectRootLink : `${projectRootLink}/branches/${getURISafeBranchSpecifier(branch)}`;
};

const branchRoutes = (root: string, projectRoot: string, gitRef: BranchSpecifier | GitRefResource | undefined) => {
    const projectRootLink = projectRoutes(root, projectRoot).root;
    let branchRoot = projectRootLink;

    if (gitRef) {
        if (isGitRefResource(gitRef)) {
            branchRoot = rootForBranchResource(projectRootLink, gitRef);
        } else {
            branchRoot = rootForBranchSpecifier(projectRootLink, gitRef);
        }
    }

    return {
        root: branchRoot,
        deploymentProcess: deploymentLinks(`${branchRoot}/deployments`).process,
        deploymentSettings: deploymentLinks(`${branchRoot}/deployments`).settings,
        variables: variablesLinks(`${branchRoot}/variables`),
        runbooks: opsRouteLinks(`${branchRoot}/operations`).runbooks,
        runbook: opsRouteLinks(`${branchRoot}/operations`).runbook(":runbookId").root,
    };
};

export type ProjectRouteLinks = ReturnType<typeof projectRoutes>;
function projectRoutes(root: string, projectSlug: string | ProjectOrSummaryResource | DashboardProjectResource) {
    if (!!projectSlug && typeof projectSlug !== "string") {
        projectSlug = projectSlug.Slug;
    }

    const projectRoot = `${root}/projects/${projectSlug}`;

    return {
        root: projectRoot,
        ...releaseRouteLinks(`${projectRoot}/releases`),
        overview: `${projectRoot}/overview`,
        settings: {
            root: `${projectRoot}/settings`,
            versionControl: `${projectRoot}/settings/versioncontrol`,
            dynamicEnvironments: `${projectRoot}/settings/dynamicenvironments`,
        },
        tasks: `${projectRoot}/tasks`,
        insights: projectInsightsLinks(`${projectRoot}/insights`),
        channels: `${projectRoot}/channels`,
        channel: channel(`${projectRoot}/channels`),
        operations: opsRouteLinks(`${projectRoot}/operations`),
        triggers: `${projectRoot}/triggers`,
        trigger: trigger(`${projectRoot}/triggers`),
        scheduledTrigger: scheduledTrigger(`${projectRoot}/triggers`),
        scheduledTriggers: {
            root: `${projectRoot}/triggers`,
            new: `${projectRoot}/triggers/scheduled/create`,
        },
        variables: variablesLinks(`${projectRoot}/variables`),
        vcsConnectivityCheck: `${projectRoot}/vcs/connectivityCheck`,
        deployments: deploymentLinks(`${projectRoot}/deployments`),
        childStepTemplates: (parentStepId: string) => ({
            root: `${projectRoot}?childStepTemplates=1&parentStepId=${parentStepId}`,
        }),
        stepTemplates: `${projectRoot}?stepTemplates=1`,
    };
}
